import React from "react";
import "./footer.scss";
import logo from "../../asset/logo.webp";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img
            className="gambar-logo"
            src={logo}
            alt="logo-citraland-surabaya"
          />
        </div>
        <div className="container-deskripsi">
          <div className="alamat">
            <h2>Marketing Gallery</h2>
            <h1>Citi Square Business Park</h1>
            <p>
              Jl. Peta Selatan, RT.10/RW.1, Kalideres, Kec. Kalideres, Kota
              Jakarta Barat, Daerah Khusus Ibukota Jakarta 11840
            </p>
          </div>
          <div className="contact">Contact us : +628992683592 (Alby)</div>
          <div className="privasi"></div>
        </div>
      </div>
      <div className="container-bawah">CITI SQUARE FARPOINT</div>
    </div>
  );
};

export default footer;
