import React from "react";
import "./lokasi.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

const lokasi = () => {
  return (
    <div className="container-lokasi">
      <div id="lokasi" className="judul-lokasi">
        OUR LOCATION
      </div>
      <div className="maps">
        <div className="img-map"></div>
      </div>
      <div className="detaillokasi">
        <div className="poinlokasi">
          <FontAwesomeIcon icon={faLocationDot} color="red" />
          &nbsp; Jakarta Barat
        </div>
        <div className="poinlokasi">
          <FontAwesomeIcon icon={faLocationDot} color="red" />
          &nbsp; Selangkah Halte Busway
        </div>
        <div className="poinlokasi">
          <FontAwesomeIcon icon={faLocationDot} color="red" />
          &nbsp; Akses Langsung Bandara Internasional Soekarno Hatta
        </div>
        <div className="poinlokasi">
          <FontAwesomeIcon icon={faLocationDot} color="red" />
          &nbsp; Akses Langsung 2 Tol
        </div>
        <div className="poinlokasi">
          <FontAwesomeIcon icon={faLocationDot} color="red" />
          &nbsp; Dekat Dengan residentials Citra Garden & Taman Palem
        </div>
      </div>
    </div>
  );
};

export default lokasi;
