import Fasilitas1 from "../../asset/banner/citisquaredrone (1).webp";
import Fasilitas2 from "../../asset/banner/citisquaredrone (2).webp";
import Fasilitas3 from "../../asset/banner/citisquaredrone (3).webp";
import Fasilitas4 from "../../asset/banner/citisquaredrone (4).webp";
import Fasilitas5 from "../../asset/banner/citisquaredrone (5).webp";

const fasilitasimages = [
  Fasilitas1,
  Fasilitas2,
  Fasilitas3,
  Fasilitas4,
  Fasilitas5,
];

export default fasilitasimages;
