import Produk1 from "../../asset/citi.webp";
import Produk2 from "../../asset/4.webp";
import Produk3 from "../../asset/5.webp";
import Produk4 from "../../asset/6.webp";
import Produk5 from "../../asset/7.webp";
import Produk6 from "../../asset/8.webp";
import Produk7 from "../../asset/9.webp";
import Produk8 from "../../asset/10.webp";
import Produk9 from "../../asset/11.webp";

const produkimages = [
  Produk1,
  Produk2,
  Produk3,
  Produk4,
  Produk5,
  Produk6,
  Produk7,
  Produk8,
  Produk9,
];

export default produkimages;
