import { faCheck } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import promoimage from "../../asset/hsell.webp";
import "./header.scss";

const header = () => {
  const wapromo = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=628992683592&text=Halo%20Alby%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20ruko%20ini%20%20(https://citisquare-farpoint.com/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="header">
      <div id="promo" className="container-header">
        <div className="headline">
          <div className="judul-header">Citi Square Business Park</div>
          <div className="desk-header">
            Kompleks perkantoran ini terletak di Kalideres, Jakarta Barat.
            Menawarkan infrastruktur dan fasilitas modern dengan lokasi
            strategis di pusat area bisnis utama Jakarta Barat.
          </div>
          <div className="list-header">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span>&nbsp;&nbsp;Siap Huni</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span>&nbsp;&nbsp;Nego Sampe Deal</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span>&nbsp;&nbsp;Discount Up to 600jt</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span>&nbsp;&nbsp;Free PPN</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span>&nbsp;&nbsp;24 Hour Security</span>
            </div>
          </div>
          <div className="button">
            <button onClick={wapromo} className="whatsapp">
              Contact Us
            </button>
          </div>
        </div>
        <div className="img-promo">
          <div className="foto">
            <img className="ft" src={promoimage} alt="promo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default header;
