import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fasilitasimages from "./fasilitas.js";
import "./fasilitas.scss";

const fasilitas = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyload: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint for your mobile size
        settings: {
          slidesToShow: 1, // Show only 1 slide on mobile screens
        },
      },
    ],
  };
  return (
    <div className="fasilitas">
      <div id="keuntungan" className="container-fasilitas">
        <div className="carousel-fasilitas">
          <Slider {...settings}>
            {fasilitasimages.map((image, index) => (
              <img
                className="kartugambar"
                key={index}
                src={image}
                alt={`Courtyard ${index + 1}`}
              />
            ))}
          </Slider>
        </div>
        <div className="desk">
          <div className="judul-fasilitas">Kenapa Harus Citi Square?</div>
          <div className="subjudul-fasilitas">
            Beberapa alasan strategis dan fasilitas yang tersedia. Berikut
            adalah beberapa kelebihan yang dapat diperoleh dari memiliki
            properti di lokasi ini:
          </div>
          <div className="list-fasilitas">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span>&nbsp;&nbsp;Bebas Banjir</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span>&nbsp;&nbsp;Keamanan 24 Jam</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span>&nbsp;&nbsp;Area Parkir Luas</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span>&nbsp;&nbsp;Akses Langsung Ke Bandara Soekarno Hatta</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span>&nbsp;&nbsp;Dekat Halte Busway</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span>
                &nbsp;&nbsp;Dekat Premium Cluster Citra Garden City & Taman Palm
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span>&nbsp;&nbsp;Dekat Halte Busway</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default fasilitas;
