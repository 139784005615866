import React from "react";
import "./navbar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import imglogo from "../../asset/logo.webp";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const navbar = () => {
  const wanavbar = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=628992683592&text=Halo%20Alby%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20ruko%20ini%20(https://citisquare-farpoint.com/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="container-navbar">
      <div className="navbar">
        <div className="navbar-logo">
          <img className="img-logo" src={imglogo} alt="logo-bukitpodomoro" />
        </div>
        <div className="button-navbar">
          <button onClick={wanavbar} className="whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} /> Hubungi Kami
          </button>
        </div>
      </div>
    </div>
  );
};

export default navbar;
