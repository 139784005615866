import React from "react";
import "./produk.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignJustify,
  faHouse,
  faLocationDot,
  faMaximize,
  faToilet,
} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import produkimages from "./produk";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const produk = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyload: true,
  };
  const k2wa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=628992683592&text=Halo%20Alby%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20ruko%20ini%20(k2)%20(https://citisquare-farpoint.com/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  const k3wa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=628992683592&text=Halo%20Alby%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20ruko%20ini%20(k3)%20(https://citisquare-farpoint.com/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  const a1wa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=628992683592&text=Halo%20Alby%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20ruko%20ini%20(a1)%20(https://citisquare-farpoint.com/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  const a2wa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=628992683592&text=Halo%20Alby%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20ruko%20ini%20(a2)%20(https://citisquare-farpoint.com/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="produk">
      <div id="ruko" className="container-produk">
        <div className="carousel-produk">
          <Slider {...settings}>
            {produkimages.map((image, index) => (
              <img
                className="kartugambar"
                key={index}
                src={image}
                alt={`Courtyard ${index + 1}`}
              />
            ))}
          </Slider>
        </div>
        <div className="judul-produk">Citi Square Business Park</div>
        <div className="subjudul-produk">
          <FontAwesomeIcon color="red" icon={faLocationDot} />
          &nbsp;&nbsp;Jakarta Barat
        </div>
        <div className="container-card">
          <div className="card-detail1">
            <div className="card">
              <div className="judul">Tipe K-2</div>
              <div className="subjudul">Free PPN, Cicilan 23 Juta/Bulan</div>
              <div className="gridspek">
                <FontAwesomeIcon icon={faMaximize} />
                <span> : 9,8 x 7,78 m</span>
                <FontAwesomeIcon icon={faHouse} />
                <span> : 244,34 m²</span>
                <FontAwesomeIcon icon={faAlignJustify} />
                <span>: 3</span>
                <FontAwesomeIcon icon={faToilet} />
                <span>: 2</span>
              </div>
              <div className="containerwhatsapp">
                <button onClick={k2wa} className="whatsapp">
                  <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                </button>
              </div>
            </div>
            <div className="card">
              <div className="judul">Tipe K-3</div>
              <div className="subjudul">Free PPN, Cicilan 23 Juta/Bulan</div>
              <div className="gridspek">
                <FontAwesomeIcon icon={faMaximize} />
                <span> : 8,5 x 4,24 m</span>
                <FontAwesomeIcon icon={faHouse} />
                <span> : 244,34 m²</span>
                <FontAwesomeIcon icon={faAlignJustify} />
                <span>: 3</span>
                <FontAwesomeIcon icon={faToilet} />
                <span>: 2</span>
              </div>
              <div className="containerwhatsapp">
                <button onClick={k3wa} className="whatsapp">
                  <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                </button>
              </div>
            </div>
          </div>
          <div className="card-detail2">
            <div className="card">
              <div className="judul">Tipe A-1 (Hoek)</div>
              <div className="subjudul">Free PPN, Cicilan 30 Juta/Bulan</div>
              <div className="gridspek">
                <FontAwesomeIcon icon={faMaximize} />
                <span> : : 4,5 x 12,5 m</span>
                <FontAwesomeIcon icon={faHouse} />
                <span> : 278,42 m²</span>
                <FontAwesomeIcon icon={faAlignJustify} />
                <span>: 4</span>
                <FontAwesomeIcon icon={faToilet} />
                <span>: 3</span>
              </div>
              <div className="containerwhatsapp">
                <button onClick={a1wa} className="whatsapp">
                  <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                </button>
              </div>
            </div>
            <div className="card">
              <div className="judul">Tipe A-2</div>
              <div className="subjudul">Free PPN, Cicilan 30 Juta/Bulan</div>
              <div className="gridspek">
                <FontAwesomeIcon icon={faMaximize} />
                <span> : 4,5 x 14 m²</span>
                <FontAwesomeIcon icon={faHouse} />
                <span> : 244,34 m²</span>
                <FontAwesomeIcon icon={faAlignJustify} />
                <span>: 4</span>
                <FontAwesomeIcon icon={faToilet} />
                <span>: 3</span>
              </div>
              <div className="containerwhatsapp">
                <button onClick={a2wa} className="whatsapp">
                  <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default produk;
